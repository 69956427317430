<template>
    <div class="dash-punch-clock-wrapper">
        <div class="dash-punch-clock-container">
            <div class="dash-punch-clock-logo-container">
                <img class="dash-punch-clock-logo" :src="company_logo_url" alt="Beautycos Logo">
            </div>
            <div class="dash-punch-clock-filterbar">
                <div class="dash-punch-clock-filterbar-all" @click="filterLetter = ''">
                    {{ $t('Show all') }}
                </div>
                <div class="dash-punch-clock-filterbar-alphabet">
                    <div style="height: fit-content;" v-for="(letter, index) in letterArray" :key="index">
                        <p @click="filterLetter = letter">{{ letter }}</p>
                    </div>
                </div>
            </div>
            <div class="scrollable">
                <table class="list table odd-even">
                    <tbody>
                        <tr class="dash-punch-clock-list-item" v-for="punchClock in showPunchClocks" :key="punchClock.id">
                            <td>{{ punchClock.user_display_name }}</td>
                            <td>{{ punchClock.start_date }}</td>
                        </tr>
                    </tbody>
                    <thead>
                        <tr>
                            <th>{{ $t('Name') }}</th>
                            <th>{{ $t('Punched In') }}</th>
                        </tr>
                    </thead>
                </table>
            </div>
        </div>
        <div class="loading" v-if="loading">
            <div class="loading-element">
                <span></span>
                <span></span>
                <span></span>
                <span></span>
            </div>
        </div>
    </div>
</template>

<script>
import { BPA } from '@/helpers/BPA'
import { Tool } from '@/helpers/Tool'

    export default {
        name: 'DashboardPunchClock',
        mixins: [ BPA, Tool ],
        data() {
            return {
                loading: false,
                getPunchClocks: false,
                letterArray: ['A', 'B', 'C', 'D', 'E', 'F', 'G', 'H', 'I', 'J', 'K', 'L', 'M', 'N', 'O', 'P', 'Q', 'R', 'S', 'T', 'U', 'V', 'W', 'X', 'Y', 'Z', 'Æ', 'Ø', 'Å'],
                currentPunchClocks: [],
                filterLetter: '',
            }
        },
        mounted() {
            this.getPunchClocks = true;
            this.GetPunchClocks()
        },
        beforeDestroy() {
            this.getPunchClocks = false;
        },
        computed: {
            showPunchClocks() {
                if(this.filterLetter == '') return this.currentPunchClocks;

                let tempPunchClock = JSON.parse(JSON.stringify(this.currentPunchClocks))

                let filteredPunchClocks = tempPunchClock.filter((punchClock) => punchClock.user_display_name.toUpperCase().startsWith(this.filterLetter))

                return filteredPunchClocks;
            },
            company_logo_url() {
                return BPA.util.GetCompanyLogo(BPA.util.GetCompany()) + '?' + new Date().getTime();
            },
        },
        methods: {
            Alphabetize(list) {
                return list.sort((a, b) => {
                    a = a.user_display_name.toUpperCase(); 
                    b = b.user_display_name.toUpperCase();
                    return a < b ? -1 : a > b ? 1 : 0;
                });
            },
            async GetPunchClockDashboard() {
                this.loading = true
                return await new Promise((resolve, reject) => {
                    BPA.api.GetPunchClockDashboard().then((response) => {
                        return BPA.api.response({ response, return: 'json'})
                    }).then((response) => {
                        if (!response.ok || !response.result) {
                            this.loading = false
                            return reject()
                        }

                        this.loading = false
                        
                        resolve(response.result)
                    })
                })
            },
            async GetPunchClocks() {
                const delay = (ms) => new Promise((res) => setTimeout(res, ms))

                do {
                    this.currentPunchClocks = await this.GetPunchClockDashboard()

                    this.Alphabetize(this.currentPunchClocks)

                    await delay(60000)
                }   while (this.getPunchClocks)
            },

        }
    }
</script>

<style lang="scss" scoped>
    .dash-punch-clock-wrapper {
        width: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        padding: 20px;
        background: #F1F2F6;
    }
    .dash-punch-clock-container {
        max-width: 900px;
        width: 100%;
        height: 100%;
        background: white;
    }
    .dash-punch-clock-logo-container {
        display: flex;
        justify-content: center;
        margin-top: 10px;
    }
    .dash-punch-clock-logo {
        height: 70px;
    }
    .dash-punch-clock-filterbar {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        width: 100%;
        padding-block: 5px;
        border-bottom: 1px solid #e8e8e8;
        position: sticky;
        margin-top: 1rem;
        gap: 10px;
    }
    .dash-punch-clock-filterbar-all {
        margin-left: 6px;
        color: blue;
        cursor: pointer;
    }
    .dash-punch-clock-filterbar-alphabet {
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
    }
    .scrollable {
        overflow-y: scroll;
        max-height: calc(100% - 10rem);
    }
    .dash-punch-clock-filterbar-alphabet p {
        margin-inline: 6px;
        color: rgb(60, 60, 235);
        cursor: pointer;
    }
    @media (max-width: 650px) {
        
    }
</style>